import React,{useState} from 'react';
import URL from '../Hooks/ConstValues';
const useValidation = () => {
   
    const Validaterole = (rolename, action) => {
        const [returnValue,setReturnValue]= useState(true);
        fetch(URL+'api/validateRoles/' + localStorage.getItem("UserData") + '/' + rolename, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            })
            .then(response =>
                response.json().then(data => { 
                    setReturnValue(data)
                })
            )
            .catch(err => {
                setReturnValue(false)
            });
           
       return returnValue;
    };
    return { Validaterole }
}

export default useValidation;