import { useState } from 'react';
import swal from 'sweetalert';
import URL from '../Hooks/ConstValues';
const useLogin = (url = ``, data = {}) => {   
    if (data.newPassword != data.confirmPassword) {
        alert("Passwords don't match.")
        return;
    }
    fetch(URL+url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        .then(response =>
            response.json().then(data => {
                if (data.status != 401) {
                    if (data.error == "Need new pasword") {
                        alert("First time login? Create new password to proceed.")
                        localStorage.removeItem("IsLogged");
                        window.location.replace("/change");
                    }
                    if (data.token != null) {
                        localStorage.removeItem("token");
                        localStorage.setItem("token", data.token);
                        localStorage.setItem("UserData", data.user.email);
                        localStorage.setItem("IsLogged", true);
                        window.location.reload();
                       
                    }
                    if (data.errors != null) {
                        swal("error!", "Error occured. Use stronger password", "error");
                    }
                } else {
                    localStorage.setItem("IsLogged", false);
                    swal("error!", "Invalid credentials. Try again.", "error");
                }
            })
        )
        .catch(err => {
            swal("", "Failed", "error");
        });
    return
}


export default useLogin;