import React, { useEffect, useReducer } from "react";
import swal from '@sweetalert/with-react';
import URL from '../Hooks/ConstValues';
const initialState = {
    data: [],
    IsLoading: true,
    error: ""
};
const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                IsLoading: false,
                data: action.payload,
                error: ""
            };
        case "FETCH_ERROR":
            return {
                IsLoading: false,
                data: [],
                error: action.payload
            };
        default:
            return state;
    }
};
const useFetch = url => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const FetchAll = () => {
        fetch(URL+url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
        .then(response => {
           
            if (response.status == 200) {   
                response.json().then(data => { 
                dispatch({ type: "FETCH_SUCCESS", payload: data }); })              
            }
            else if(response.status == 401 ||response.status == 403){
                dispatch({ type: "FETCH_ERROR", payload: "Access denied." });
                swal("Access denied.")
            } else {
                dispatch({ type: "FETCH_ERROR", payload: "Request failed" });
                swal("Request failed.")
               }
        }
        )
        .catch(err => {
            dispatch({ type: "FETCH_ERROR", payload: err.message });
            swal("Request failed.")
        });
    }

    return { state, FetchAll };
};

export default useFetch;