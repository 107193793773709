import React, { useEffect, useState } from "react";
import DataTable from "../Components/DisplayTable";
import useGetData from "../Hooks/useGetData";

const ApprovedLeaves = () => {
    const url = "api/leaveapplication/";
    const uri = "api/LeaveApplication/Approved/";
    const [state, FetchAll] = useGetData(uri);


    useEffect(() => {
        FetchAll();
    }, []);

    let TableHeader = [
        "Employee",
        "Leave Type",
        "Start Date",
        "End Date",
        "Leave days",
        "Status"
    ];
    let rowData = [
        "employee.email",
        "leaveType.description",
        "start",
        "end",
        "leaveDays",
        "status"
    ];


    return (
        <div>
            {state.IsLoading ? (
                <div id="page-loader" className="fade show"><span className="spinner"></span></div>
            ) : (
               
                    <DataTable
                        page={"Approved Leaves"}
                        data={state.data}                       
                        rowData={rowData}
                        TableHeader={TableHeader}
                    />
                     
                )}
               
        </div>
    );
};

export default ApprovedLeaves;
