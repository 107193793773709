import React from 'react';
const DashBoard = () => {
    return (
        <div>



            <ol className="breadcrumb pull-right">

            </ol>

            <h1 className="page-header">Dashboard </h1>
            <div className="row">

                <div className="col-lg-3 col-md-6">
                    <div className="widget widget-stats bg-red">
                        <div className="stats-icon"><i className="fa fa-desktop"></i></div>
                        <div className="stats-info">
                            <h4>Leave Applications</h4>
                            <p>3</p>
                        </div>
                        <div className="stats-link">
                            <a >View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="widget widget-stats bg-orange">
                        <div className="stats-icon"><i className="fa fa-link"></i></div>
                        <div className="stats-info">
                            <h4>Approved Leaves</h4>
                            <p>2</p>
                        </div>
                        <div className="stats-link">
                            <a >View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="widget widget-stats bg-grey-darker">
                        <div className="stats-icon"><i className="fa fa-users"></i></div>
                        <div className="stats-info">
                            <h4>Pending Approval</h4>
                            <p>1</p>
                        </div>
                        <div className="stats-link">
                            <a >View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="widget widget-stats bg-black-lighter">
                        <div className="stats-icon"><i className="fa fa-clock"></i></div>
                        <div className="stats-info">
                            <h4>Total Employees</h4>
                            <p>15</p>
                        </div>
                        <div className="stats-link">
                            <a >View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row">

                <div className="col-lg-8">

                    <div className="panel panel-inverse" data-sortable-id="index-1">
                        <div className="panel-heading">
                            <div className="panel-heading-btn">
                                <a className="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i className="fa fa-expand"></i></a>
                                <a className="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i className="fa fa-redo"></i></a>
                                <a className="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i className="fa fa-minus"></i></a>
                                <a className="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i className="fa fa-times"></i></a>
                            </div>
                            <h4 className="panel-title">Performance Analytics (Last 7 Days)</h4>
                        </div>
                        <div className="panel-body">
                            <div id="interactive-chart" className="height-sm"></div>
                        </div>
                    </div>

                </div>

                <div className="col-lg-4">

                    <div className="panel panel-inverse" data-sortable-id="index-7">
                        <div className="panel-heading">
                            <div className="panel-heading-btn">
                                <a className="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i className="fa fa-expand"></i></a>
                                <a className="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i className="fa fa-redo"></i></a>
                                <a className="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i className="fa fa-minus"></i></a>
                                <a className="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i className="fa fa-times"></i></a>
                            </div>
                            <h4 className="panel-title">World Visitors</h4>
                        </div>
                        <div className="panel-body p-0">
                            <div id="world-map" className="height-sm width-full"></div>
                        </div>
                    </div>
                </div>

            </div>




        </div>
    );
}

export default DashBoard;