import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
import Errors from '../Components/Errors'
import URL from '../Hooks/ConstValues';

const useLeaveApproval = (url = ``, data = {}) => {
    swal({
        text: "Submit this request?",
        icon: "warning",
        dangerMode: false,
        buttons: true
    }).then(willDelete => {
        if (willDelete) {
            fetch(URL+url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(data)
            })
                .then(response => {
                    if (response.status == 200) {
                        swal("Success!", "Request was successfull", "success");
                        window.location.reload();
                    } else if (response.status == 401 || response.status == 403) {
                        swal("Access denied.")
                    } else {
                        response.json().then(data => {
                            if (data.errors != null) {
                                let Values = Object.values(data.errors)
                                swal(<Errors Errors={Values} />)
                            } else {
                                swal("Request failed.")
                            }
                        })
                    }
                }
                    // response.json().then(Data => {                     
                    //     if (Data.errors == null) {
                    //         swal("Saved!", "Request was successfull", "success");
                    //         window.location.reload();
                    //     } else {
                    //         swal("Saved!", Data.errors.Name[0], "error");
                    //     }
                    // })
                )
                .catch(err => {
                    swal("", "Failed", "error");
                });
        }
    })

}


export default useLeaveApproval;