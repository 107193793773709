import React from 'react';
import useInput from '../Hooks/useInput';
import useLogin from '../Hooks/useLogin';

const Login = () => {

    const url = "api/login/";

    const initialState = {
        username: "",
        password: ""

    };

    const {
        values,
        HandleChange,
        reset,
    } = useInput(initialState);

    const HandleSubmit = e => {
        e.preventDefault();
        useLogin(url, values);
        reset();
    };
    return (
        <div className="pace-top bg-white">
            <div id="page-container" className="fade">

                <div className="login login-with-news-feed">

                    <div className="news-feed">
                        <div className="news-image" style={{ backgroundImage: `url(/assets/img/login-bg/login-bg-11.jpg)` }}></div>
                        <div className="news-caption">
                            <h4 className="caption-title"><b>HR</b> Management</h4>
                            <p>
                                Download the Hr Management app for iPhone®, iPad®, and Android™.
					</p>
                        </div>
                    </div>

                    <div className="right-content">

                        <div className="login-header">
                            <div className="brand">
                                <span className="logo"></span> <b>HR</b> Management
						{/* <small>responsive bootstrap 4 admin template</small> */}
                            </div>
                            <div className="icon">
                                <i className="fa fa-sign-in"></i>
                            </div>
                        </div>

                        <div className="login-content">
                            <form className="margin-bottom-0">
                                <div className="form-group m-b-15">
                                    <input type="text"
                                        name="username"
                                        value={values.username}
                                        onChange={HandleChange}
                                        className="form-control form-control-lg" placeholder="Username/Email Address" required />
                                </div>
                                <div className="form-group m-b-15">
                                    <input type="password"
                                        name="password"
                                        value={values.password}
                                        onChange={HandleChange}
                                        className="form-control form-control-lg" placeholder="Password" required />
                                </div>
                                <div className="checkbox checkbox-css m-b-30">
                                    <input type="checkbox" id="remember_me_checkbox" value="" />
                                    <label htmlFor="remember_me_checkbox">
                                        Remember Me
							</label>
                                </div>
                                <div className="login-buttons">
                                    <button onClick={HandleSubmit} type="submit" className="btn btn-success btn-block btn-lg">Sign me in</button>
                                </div>
                                {/* <div className="m-t-20 m-b-40 p-b-40 text-inverse">
                                    Not a member yet? Click <a href="register_v3.html" className="text-success">here</a> to register.
						</div> */}
                                <hr />
                                <p className="text-center text-grey-darker">
                                    &copy;iMaster All Right Reserved 2020
						</p>
                            </form>
                        </div>

                    </div>

                </div>


            </div>


        </div>
    );
}

export default Login;