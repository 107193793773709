import React, { useEffect, useState } from "react";
import DataTable from "../Components/DeleteTable";
import useInput from "../Hooks/useInput";
import usePostData from "../Hooks/usePostData";
import useDelete from "../Hooks/useDelete";
import Input, { Label } from "../Components/Input";
import Select from "react-select";
import useGetData from "../Hooks/useGetData";

const UserRoles = () => {
    const [Editing, setEditing] = useState(false);
    const initialState = {
        roleId: null,
        usersId: null,

    };
    const url = "api/account/userRoles";
    const [state, FetchAll] = useGetData(url);
    const { HandleDelete, HandleEditData, HandleRoleDelete } = useDelete(url);
    const {
        values,
        HandleChange,
        reset,
        FillData,
        handleSelectChange,
        selectOptions
    } = useInput(initialState);

    useEffect(() => {
        FetchAll();
    }, []);

    let TableHeader = [
        "UserName",
        "Email",
        "Role",
        "Action"

    ];
    let rowData = [
        "userName",
        "user",
        "role"

    ];

    const handleEdit = k => {
        const data = {
            roleId: k.roleId,
            usersId: k.usersId,


        };
        FillData(data);
        setEditing(true);
    };
    const handleDelete = k => {
        HandleRoleDelete(k);
    };
    const HandleSubmit = e => {
        e.preventDefault();
        usePostData(url, values);

        reset();
    };
    const HandleUpdate = e => {
        e.preventDefault();
        HandleEditData(values);
        reset();
    };
    const HandleOnClose = e => {
        e.preventDefault();
        setEditing(false);
        reset();
    };
    return (
        <div>
            {state.IsLoading ? (
                <div id="page-loader" className="fade show"><span className="spinner"></span></div>
            ) : (
                    <DataTable
                        page={"User Roles"}
                        data={state.data}
                        HandleOnClose={HandleOnClose}
                        handleEdit={e => handleEdit(e)}
                        handleDelete={e => handleDelete(e)}
                        rowData={rowData}
                        TableHeader={TableHeader}
                    >
                        <UserRolesForm
                            values={values}
                            selectOptions={selectOptions}
                            IsEditing={Editing}
                            HandleUpdate={HandleUpdate}
                            HandleOnClose={HandleOnClose}
                            HandleSubmit={HandleSubmit}
                            handleSelectChange={handleSelectChange}
                            HandleChange={HandleChange}
                        />
                    </DataTable>
                )}
        </div>
    );
};

export default UserRoles;

const UserRolesForm = props => {
    const [Users, Fetch] = useGetData("api/account");
    const [Roles, FetchRoles] = useGetData("api/Roles");

    const UserOptions = Users.data.map((k, i) => {
        return {
            value: k.id.toString(),
            label: k.email.toString()
        };
    });
    const RoleOptions = Roles.data.map((k, i) => {
        return {
            value: k.name.toString(),
            label: k.name.toString()
        };
    });
    useEffect(() => {
        Fetch();
        FetchRoles();
    }, []);

    return (
        <div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-10 md-offset-2">
                        <div className="form-group row m-b-10">
                            <Label label="User" isRequired={true} />
                            <div className="col-md-6">
                                <Select
                                    name="usersId"
                                    className="form-group"
                                    defaultInputValue={props.selectOptions.usersId}
                                    value={props.selectOptions.usersId}
                                    onChange={props.handleSelectChange}
                                    options={UserOptions}
                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-10">
                            <Label label="Role" isRequired={true} />
                            <div className="col-md-6">
                                <Select
                                    name="roleId"
                                    className="form-group"
                                    defaultInputValue={props.selectOptions.roleId}
                                    value={props.selectOptions.roleId}
                                    onChange={props.handleSelectChange}
                                    options={RoleOptions}
                                />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a
                    className="btn btn-white"
                    data-dismiss="modal"
                    onClick={props.HandleOnClose}
                >
                    Close
        </a>
                {props.IsEditing ? (
                    <button
                        type="submit"
                        onClick={props.HandleUpdate}
                        className="btn btn-primary"
                    >
                        Update
          </button>
                ) : (
                        <button
                            type="submit"
                            onClick={props.HandleSubmit}
                            className="btn btn-primary"
                        >
                            Save
          </button>
                    )}
            </div>
        </div>
    );
};
