import React from "react";
const Breadcrump = () => {
  return (
 
      <ol className='breadcrumb pull-right'>
        {/* <li className='breadcrumb-item'>
          <a href='javascript:;'>Home</a>
        </li> */}
        
        <li className='breadcrumb-item active'>Human Resource Management System</li>
      </ol>
   
  );
};

export default Breadcrump;
