import React, { useEffect, useState } from "react";
import DataTable from "../Components/DeleteTable";
import useInput from "../Hooks/useInput";
import usePostData from "../Hooks/usePostData";
import useDelete from "../Hooks/useDelete";
import Input, { Label } from "../Components/Input";
import Select from "react-select";
import useGetData from "../Hooks/useGetData";

const PendingLeaves = () => {
    const [Editing, setEditing] = useState(false);
    const initialState = {
        leaveTypeId: null,
        remarks: 'Remarks',
        start: new Date(),
        leaveDays: 0,
        rendering: 'background'
    };
    const url = "api/leaveapplication/";
    const uri = "api/LeaveApplication/pending/";
    const [state, FetchAll] = useGetData(uri);
    const { HandleDelete, HandleEditData } = useDelete(url);
    const {
        values,
        HandleChange,
        reset,
        FillData,
        handleSelectChange,
        selectOptions
    } = useInput(initialState);

    useEffect(() => {
        FetchAll();
    }, []);

    let TableHeader = [
        "Employee",
        "Leave Type",
        "Start Date",
        "End Date",
        "Leave days",
        "Status",
        "Action"
    ];
    let rowData = [
        "employee.email",
        "leaveType.description",
        "start",
        "end",
        "leaveDays",
        "status"
    ];

    const handleEdit = k => {
        const data = {
            id: k.id,
            leaveTypeId: k.leaveTypeId,
            start: k.start,
            leaveDays: k.leaveDays,
            rendering: k.rendering,
            remarks: k.remarks
        };
        FillData(data, {
            leaveTypeId: {
                value: k.leaveTypeId || "",
                label: (k.leaveType || "").description || ""
            }
        });
        setEditing(true);
    };
    const handleDelete = k => {
        HandleDelete(k.id);
    };
    const HandleSubmit = e => {
        e.preventDefault();
        usePostData(url, values);

        reset();
    };
    const HandleUpdate = e => {
        e.preventDefault();
        HandleEditData(values);
        reset();
    };
    const HandleOnClose = e => {
        e.preventDefault();
        setEditing(false);
        reset();
    };
    return (
        <div>
            {state.IsLoading ? (
                <div id="page-loader" className="fade show"><span className="spinner"></span></div>
            ) : (
                    <DataTable
                        page={"Pending Leaves"}
                        data={state.data}
                        HandleOnClose={HandleOnClose}
                        handleEdit={e => handleEdit(e)}
                        handleDelete={e => handleDelete(e)}
                        rowData={rowData}
                        TableHeader={TableHeader}
                    >
                        <PendingLeavesForm
                            values={values}
                            selectOptions={selectOptions}
                            IsEditing={Editing}
                            HandleUpdate={HandleUpdate}
                            HandleOnClose={HandleOnClose}
                            HandleSubmit={HandleSubmit}
                            handleSelectChange={handleSelectChange}
                            HandleChange={HandleChange}
                        />
                    </DataTable>
                )}
        </div>
    );
};

export default PendingLeaves;

const PendingLeavesForm = props => {
    const [LeaveTypes, FetchLeaves] = useGetData("api/LeaveTypes");

    const LeaveOptions = LeaveTypes.data.map((k, i) => {
        return {
            value: (k.id) || "",
            label: (k.description) || ""
        };
    });
    useEffect(() => {
        FetchLeaves();

    }, []);

    return (
        <div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-10 md-offset-2">
                        <div className="form-group row m-b-10">
                            <Label label="Leave Type" isRequired={true} />
                            <div className="col-md-6">
                                <Select
                                    name="leaveTypeId"
                                    className="form-group"
                                    defaultInputValue={props.selectOptions.leaveTypeId}
                                    value={props.selectOptions.leaveTypeId}
                                    onChange={props.handleSelectChange}
                                    options={LeaveOptions}
                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-10">
                            <Label label="Start Date" isRequired={true} />
                            <div className="col-md-6">
                                <input
                                    type='date'
                                    name='start'
                                    required
                                    onChange={props.HandleChange}
                                    value={props.values.start}
                                    className='form-control'

                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-10">
                            <Label label="Leave days" isRequired={true} />
                            <div className="col-md-6">
                                <input
                                    type='number'
                                    name='leaveDays'
                                    required
                                    onChange={props.HandleChange}
                                    value={props.values.leaveDays}
                                    className='form-control'

                                />
                            </div>
                        </div>

                        <div className="form-group row m-b-10">
                            <Label label="Remarks" isRequired={true} />
                            <div className="col-md-6">
                                <textarea name='remarks'
                                    required
                                    onChange={props.HandleChange}
                                    value={props.values.remarks} className="form-control" rows="3"></textarea>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a
                    className="btn btn-white"
                    data-dismiss="modal"
                    onClick={props.HandleOnClose}
                >
                    Close
        </a>
                {props.IsEditing ? (
                    <button
                        type="submit"
                        onClick={props.HandleUpdate}
                        className="btn btn-primary"
                    >
                        Update
                     </button>
                ) : (
                        <button
                            type="submit"
                            onClick={props.HandleSubmit}
                            className="btn btn-primary"
                        >
                            Save
          </button>
                    )}
            </div>
        </div>
    );
};
