import React from "react";
import { Link } from "react-router-dom";
import useValidation from "../Hooks/useValidation";
const { Validaterole } = useValidation();
const Sidebar = () => {
  const user = localStorage.getItem("UserData");

  return (
    <div>
      <div id="sidebar" className="sidebar">
        <div data-scrollbar="true" data-height="100%">
          <ul className="nav">
            <li className="nav-profile">
              <a data-toggle="nav-profile">
                <div className="cover with-shadow"></div>
                <div className="image">
                  {/* <img height="90" width="90" src="assets/img/user/user-13.png" alt="" /> */}
                </div>
                <div className="info">
                  <b className="caret pull-right"></b>
                  {user}
                  {/* <small>Software developer</small> */}
                </div>
              </a>
            </li>
            <li>
              <ul className="nav nav-profile">
                <li><Link to="/"> <i className="fa fa-cog"></i> Edit Profile</Link></li>
                <li><Link to="/Calender"><i className="fa fa-calendar-alt"></i> Calender</Link></li>
                <li><Link to="/logout"> <i className="fa fa-sign-out-alt"></i> Log out</Link></li>
              </ul>
            </li>
          </ul>

          <ul className="nav">
            <li className="nav-header">Navigation</li>
            {Validaterole("HRManager", "View") ? <HR /> : null}
            {Validaterole("Admin|User|HRManager", "View") ? <HolidayCompensation /> : null}
            {Validaterole("Admin|User|HRManager", "View") ? <LeaveManagement /> : null}
            {Validaterole("HRManager", "View") ? <Performance /> : null}
            {Validaterole("Admin|HRManager", "View") ? <SystemAdmin /> : null}
            {Validaterole("Admin|HRManager", "View") ? <Configurations /> : null}
            <li>
              <a

                className="sidebar-minify-btn"
                data-click="sidebar-minify"
              >
                <i className="fa fa-angle-double-left"></i>
              </a>
            </li>
            {/* <!-- end sidebar minify button --> */}
          </ul>
          {/* <!-- end sidebar nav --> */}
        </div>
        {/* <!-- end sidebar scrollbar --> */}
      </div>
      <div className="sidebar-bg"></div>
    </div>
  );
};
const SystemAdmin = () => {
  return (
    <li className="has-sub">
      <a >
        <b className="caret"></b>
        <i className="fa fa-th-large"></i>
        <span>System Admin</span>
      </a>
      <ul className="sub-menu">
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/Company"> Company</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/CostCenter"> Cost Center</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            <Link to="/Department">Department</Link>
          </li>
        ) : null}
        {/* {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/users"> Users</Link>
          </li>
        ) : null} */}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/roles"> Roles</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/userRoles">User Roles</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/approvers"> Approvers</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/leaveTypes"> leave Types</Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};
const Configurations = () => {
  return (
    <li className="has-sub">
      <a >
        <b className="caret"></b>
        <i className="fa fa-cog"></i>
        <span>Configurations</span>
      </a>
      <ul className="sub-menu">
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/bank"> Banks</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/BankBranch"> BankBranch</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            <Link to="/Currency">Currency</Link>

          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            <Link to="/Country">Country</Link>
          </li>
        ) : null}

        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            <Link to="/UCCategories">UC Categories</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            <Link to="/UserCodes">User Codes</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            <Link to="/Contact">Contacts</Link>
          </li>
        ) : null}
        {Validaterole("Admin|HRManager", "View") ? (
          <li>
            <Link to="/County">County</Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};

const HR = () => {
  return (
    <li className="has-sub">
      <a>
        <b className="caret"></b>

        <i className="fa fa-briefcase"></i>
        <span>HR Management</span>
      </a>
      <ul className="sub-menu">
        {Validaterole("HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/Employees"> Employees</Link>
          </li>
        ) : null}
        {Validaterole("HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/Employeemaster"> Add Employee</Link>
          </li>
        ) : null}
        {Validaterole("HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/leaveapplications"> Leave Applications</Link>
          </li>
        ) : null}
        {/* {Validaterole("HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/documents">Documents</Link>
          </li>
        ) : null} */}

      </ul>
    </li>
  );
};

const Performance = () => {
  return (
    <li className="has-sub">
      <a >
        <b className="caret"></b>
        <i className="fa fa-trophy"></i>
        <span>Performance</span>
      </a>
      <ul className="sub-menu">
        {Validaterole("HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/kpis"> KPIs</Link>
          </li>
        ) : null}
        {Validaterole("HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/KpiEntry"> KPI Entry</Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};

const LeaveManagement = () => {
  return (
    <li className="has-sub">
      <a >
        <b className="caret"></b>
        <i className="fa fa-flag"></i>
        <span>Leave Management</span>
      </a>
      <ul className="sub-menu">


        {Validaterole("Admin|User|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/Calender"> Request Leave</Link>
          </li>
        ) : null}

        {Validaterole("Admin|User|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/LeaveApproval">Leave Approval</Link>
          </li>
        ) : null}
        {Validaterole("Admin|User|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/PendingLeaves">Leaves Pending</Link>
          </li>
        ) : null}
        {Validaterole("Admin|User|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/CancelledLeaves">Cancelled Leaves</Link>
          </li>
        ) : null}
        {Validaterole("Admin|User|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/ApprovedLeaves">Approved Leaves</Link>
          </li>
        ) : null}
        {Validaterole("Admin|User|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/RejectedLeaves">Rejected Leaves</Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};
const HolidayCompensation = () => {
  return (
    <li className="has-sub">
      <a >
        <b className="caret"></b>
        <i className="fa fa-file-invoice-dollar"></i>
        <span>Holiday Compensation</span>
      </a>
      <ul className="sub-menu">
        {Validaterole("Admin|User|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/HolidayCompensation">Holiday Compensation</Link>
          </li>
        ) : null}
        {Validaterole("Admin|User|HRManager", "View") ? (
          <li>
            {" "}
            <Link to="/HCompensationApproval">Approve Compensation</Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};
export default Sidebar;
