import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
import Errors from '../Components/Errors'
import URL from '../Hooks/ConstValues';
const usePostData = (url = ``, data = {}) => {
    fetch(URL+url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
        .then(response =>
            {
                if (response.status == 200) {
                    swal("Saved!", "Record has been saved!", "success");
                    window.location.reload();
                }
                else if(response.status == 401 ||response.status == 403){
                    swal("Access denied.")
                } else {
                    response.json().then(data =>   
                   {
                     if(data.errors!=null){
                        let Values =Object.values(data.errors)
                        swal(<Errors Errors={Values}/>)
                     }else{
                         swal("Request failed.")
                     }
                   } )}
                
            }
           
        )
        .catch(err => {
            swal("", "Failed", "error");
        });
}


export default usePostData;

