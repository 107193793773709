import React, { useEffect, useState } from "react";
import DataTable from "../Components/DisplayTable";
import useGetData from "../Hooks/useGetData";

const LeaveApplications = () => {
    const url = "api/leaveapplication/";
    const uri = "api/LeaveApplication/all/";
    const [state, FetchAll] = useGetData(uri);


    useEffect(() => {
        FetchAll();
    }, []);

    let TableHeader = [
        "Employee",
        "Department",
        "Leave Type",
        "Start Date",
        "End Date",
        "Leave days",
        "Status",
        "Last Approver"
    ];
    let rowData = [
        "employee.email",
        "department.name",
        "leaveType.description",
        "start",
        "end",
        "leaveDays",
        "status",
        "lastApprover.email"
    ];


    return (
        <div>
            {state.IsLoading ? (
                <div id="page-loader" className="fade show"><span className="spinner"></span></div>
            ) : (

                    <DataTable
                        page={"Leave applications"}
                        data={state.data}
                        rowData={rowData}
                        TableHeader={TableHeader}
                    />

                )}

        </div>
    );
};

export default LeaveApplications;
