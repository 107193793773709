import React from 'react';
import swal from '@sweetalert/with-react';
const Errors = (props) => {
   const Errors = props.Errors.map(data => <li  key={data[0]}>  <span className="fa-li"><i className="fas fa-check-square"></i></span> {data[0]}</li>);

    return ( 
    <ul className="fa-ul">
        {Errors}
    </ul>
        );
}
 
export default Errors;