import React, { useEffect, useState } from "react";
import DataTable from "../Components/DataTable";
import Base from "./Base";
import useFetch from "../Hooks/useFetch";
import useInput from "../Hooks/useInput";
import usePostData from "../Hooks/usePostData";
import useDelete from "../Hooks/useDelete";
import Input, { Label } from "../Components/Input"

const Roles = () => {
    const [Editing, setEditing] = useState(false)
    const initialState = {
        name: ""
    };
    const url = "api/Roles/";
    const { state, FetchAll } = useFetch(url);
    const { HandleDelete, HandleEditData } = useDelete(url);
    const { values, HandleChange, reset, FillData } = useInput(initialState);

    useEffect(() => {
        FetchAll();
    }, []);

    let TableHeader = ["Role Name", "Action"];
    let rowData = ["name"];

    const handleEdit = k => {
        const data = {
            id: k.id,
            name: k.name

        };
        FillData(data)
        setEditing(true)

    };
    const handleDelete = k => {
        HandleDelete(k);

    };
    const HandleSubmit = e => {
        e.preventDefault();
        usePostData(url, values);
        reset();
    };
    const HandleUpdate = e => {
        e.preventDefault();
        HandleEditData(values)
        reset();
    };
    const HandleOnClose = e => {
        e.preventDefault();
        setEditing(false)
        reset();
    };
    return (
        <div>
            {state.IsLoading ? (
                <div id="page-loader" className="fade show"><span className="spinner"></span></div>
            ) : (
                    <DataTable
                        page={"Roles"}
                        data={state.data}
                        HandleOnClose={HandleOnClose}
                        handleEdit={e => handleEdit(e)}
                        handleDelete={e => handleDelete(e)}
                        rowData={rowData}
                        TableHeader={TableHeader}
                    >
                        <CurrenyForm
                            values={values}
                            IsEditing={Editing}
                            HandleUpdate={HandleUpdate}
                            HandleOnClose={HandleOnClose}
                            HandleSubmit={HandleSubmit}
                            HandleChange={HandleChange} />
                    </DataTable>
                )}
        </div>
    );
};

export default Roles;

const CurrenyForm = (props) => {
    return (
        <div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-10 md-offset-2">
                        <div className="form-group row m-b-10">
                            <Label
                                label="Role Name"
                                isRequired={true} />
                            <div className="col-md-6">
                                <Input
                                    name="name"
                                    value={props.values.name}
                                    HandleChange={props.HandleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a className="btn btn-white" data-dismiss="modal" onClick={props.HandleOnClose}>
                    Close
        </a>
                {props.IsEditing ? (<button
                    type="submit"
                    onClick={props.HandleUpdate}
                    className="btn btn-primary"
                >
                    Update
        </button>) : (<button
                        type="submit"
                        onClick={props.HandleSubmit}
                        className="btn btn-primary"
                    >
                        Save
        </button>)}
            </div>
        </div>
    );
};