import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
import Errors from '../Components/Errors';
import URL from '../Hooks/ConstValues';

const useDelete = (url) => {
        const [loading, setLoading] = useState(true)
        const HandleDelete = (e) => {
            swal({
                title: "Are you sure?",
                text: "Are you sure that you want to delete this record?",
                icon: "warning",
                dangerMode: false
            }).then(willDelete => {
                    if (willDelete) {
                        fetch(URL + url + e, {
                                method: "Delete",
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                                }
                            })
                            .then(response => {
                                    setLoading(false)
                                    if (response.status == 200) {
                                        swal("Deleted!", "Record has been deleted!", "success");
                                        window.location.reload();
                                    } else if (response.status == 401 || response.status == 403) {
                                        swal("Access denied.")
                                    } else {
                                        response.json().then(data => {
                                                if (data.errors != null) {
                                                    let Values = Object.values(data.errors)
                                                    swal( < Errors Errors = { Values }
                                                        />)
                                                    }
                                                    else {
                                                        swal("Request failed.")
                                                    }
                                                })
                                        }
                                    }
                                    // response.json().then(data => {
                                    //     if (data.errors == null) {
                                    //         setLoading(false)
                                    //         swal("Deleted!", "Record has been deleted!", "success");
                                    //         window.location.reload();
                                    //     } else {
                                    //         setLoading(false)
                                    //         swal("error!", data.errors.Name[0], "error");
                                    //     }
                                    // })
                                )
                                .catch(err => {
                                    setLoading(false)
                                    swal("Oops!", "Sorry an error occured.Try again", "error");
                                });
                            }
                    });

            }


            const HandleRoleDelete = (e) => {
                swal({
                    title: "Are you sure?",
                    text: "Are you sure that you want to delete this record?",
                    icon: "warning",
                    dangerMode: false
                }).then(willDelete => {
                    if (willDelete) {
                        fetch(URL + url, {
                                method: "Delete",
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                                },
                                body: JSON.stringify(e)
                            })
                            .then(response =>
                                response.json().then(data => {
                                    if (data.errors == null) {
                                        setLoading(false)
                                        swal("Deleted!", "Record has been deleted!", "success");
                                        window.location.reload();
                                    } else {
                                        setLoading(false)
                                        swal("error!", data.errors.Name[0], "error");
                                    }
                                })
                            )
                            .catch(err => {
                                setLoading(false)
                                swal("Oops!", "Sorry an error occured.Try again", "error");
                            });
                    }
                });

            }
            const HandleEditData = (data) => {
                fetch(URL + url + data.id, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(data)
                    })
                    .then(response =>
                        response.json().then(data => {

                            if (data.errors == null) {
                                swal("Saved!", "Record has been edited!", "success");
                                window.location.reload();
                            } else {
                                swal("Saved!", data.errors.Name[0], "error");
                            }
                        })
                    )
                    .catch(err => {
                        swal("Oops!", "Failed", "error");
                    });
            }
            return { HandleDelete, HandleEditData, HandleRoleDelete }
        }

        export default useDelete;