import React, { useEffect, useState } from "react";
import DataTable from "../Components/ApproversTable";
import useInput from "../Hooks/useInput";
import useGetData from "../Hooks/useGetData";
import useLeaveApproval from "../Hooks/useLeaveApproval";
import swal from '@sweetalert/with-react';
const url = "api/CompensationWorkflow/";

const CompensationApproval = () => {

    const [Editing, setEditing] = useState(false);
    const [state, FetchAll] = useGetData(url);


    useEffect(() => {
        FetchAll();
    }, []);

    let TableHeader = [
        "Employee",
        "Holiday",
        "Start Date",
        "End Date",
        "Days Worked",
        "Amount",
        "Action"
    ];
    let rowData = [
        "application.employee.email",
        "application.holiday",
        "application.start",
        "application.end",
        "application.daysWorked",
        "application.amount"
    ];

    const HandleHolidayApproval = k => {
        swal({
            text: "Select days worked by the employee",
            buttons: {
                cancel: "Close",
            },
            content: (
                <ApprovalForm Id={k.id} Days={k.application.daysWorked} Amount={k.application.amount}/>)
        }
        )

    };
    const HandleHolidayDecline = k => {
        swal({
            text: "Reject this application!",
            buttons: {
                cancel: "Close",
            },
            content: (
                <DeclineLeave Id={k.id}  />)
        }
        )
    }


    return (
        <div>
            {state.IsLoading ? (
                <div id="page-loader" className="fade show"><span className="spinner"></span></div>
            ) : (
                    <DataTable
                        page={"Holiday Compensation Approval"}
                        data={state.data}
                        HandleApproval={e => HandleHolidayApproval(e)}
                        HandleDecline={e => HandleHolidayDecline(e)}
                        rowData={rowData}
                        TableHeader={TableHeader}
                    >

                    </DataTable>
                )}
        </div>
    );
};

export default CompensationApproval;

const ApprovalForm = (props) => {
    const initialState = {
        daysWorked: props.Days,
        amount: props.Amount,
        remarks: ""

    };

    const {
        values,
        HandleChange,
        reset,
    } = useInput(initialState);

    const HandleSubmit = () => {
        useLeaveApproval(url + props.Id, values);
    }

    return (<div>
        <form onSubmit={HandleSubmit} className="margin-bottom-0">
            <div className="form-group m-b-15">
                <input type="number"
                    name="daysWorked"
                    value={values.daysWorked}
                    onChange={HandleChange}
                    className="form-control form-control-lg" placeholder="Days Worked" required />
            </div>
            <div className="form-group m-b-15">
                <input type="number"
                    name="amount"
                    value={values.amount}
                    onChange={HandleChange}
                    className="form-control form-control-lg" placeholder="Amount" required />
            </div>

            <div className="form-group m-b-15">
                <textarea name='remarks'                    
                    onChange={HandleChange}
                    value={values.remarks} placeholder="Remarks" className="form-control" rows="3"></textarea>
            </div>
            <button type="submit" className="btn btn-success btn-block btn-lg">Approve</button>
        </form>
    </div >);
}


const DeclineLeave = (props) => {
    const initialState = {
        remarks: ""

    };

    const {
        values,
        HandleChange,
        reset,
    } = useInput(initialState);

    const HandleSubmit = () => {
        useLeaveApproval(url + 'decline/' + props.Id, values);

    }

    return (<div>
        <form onSubmit={HandleSubmit} className="margin-bottom-0">

            <div className="form-group m-b-15">
                <textarea name='remarks'
                    required
                    onChange={HandleChange}
                    value={values.remarks} placeholder="Remarks" className="form-control" rows="3"></textarea>
            </div>
            <button type="submit" className="btn btn-success btn-block btn-lg">Reject</button>
        </form>
    </div >);
}



