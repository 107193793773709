import React, { useState, useEffect } from "react";
import { BrowserRouter, Router, Route, Switch } from "react-router-dom";
import Bank from "./pages/Banks";
import Currency from "./pages/Currency";
import Country from "./pages/Country";
import County from "./pages/County";
import Department from "./pages/Department";
import Company from "./pages/Company";
import Base from "./pages/Base";
import CostCenter from "./pages/CostCenter";
import Kpi from "./pages/Kpi";
import Contact from "./pages/Contact";
import BankBranch from "./pages/BankBranch";
import LeaveTypes from "./pages/LeaveTypes";
import UCCategories from "./pages/UCCategories";
import UserCodes from "./pages/UserCodes";
import Approvers from "./pages/Approvers";
import Employee from "./pages/Employee";
import Calender from "./pages/Calender";
import EmployeeMaster from "./pages/EmployeeMaster";
import LeaveApproval from "./pages/LeaveApproval";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";
import Logout from "./pages/Logout";
import HolidayCompensation from "./pages/HolidayCompensation";
import HCompensationApproval from "./pages/HCompensationApproval";
import PendingLeaves from "./pages/PendingLeaves";
import ApprovedLeaves from "./pages/ApprovedLeaves";
import RejectedLeaves from "./pages/RejectedLeaves";
import CancelledLeaves from "./pages/CancelledLeaves";
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import UserRoles from "./pages/UserRoles";
import AllLeaveApplications from "./pages/AllLeaveApplications";

function App() {
    const [IsLogged, setIslogged] = useState(false);
    useEffect(() => {
        setIslogged(localStorage.getItem("IsLogged"));
        setTimeout(function() {
            localStorage.removeItem("IsLogged");
            window.location.replace("/");
        }, 100000000);

    }, []);
    if (!IsLogged) {
        return ( <
            BrowserRouter >
            <
            Switch >
            <
            Route path = "/"
            exact component = { Login }
            /> <
            Route path = "/change"
            exact component = { ChangePassword }
            /> < /
            Switch > < /
            BrowserRouter >
        );
    } else {
        return ( <
            BrowserRouter >
            <
            Switch >
            <
            Base >

            <
            Route path = "/"
            exact component = { Calender }
            /> <
            Route path = "/change"
            exact component = { Calender }
            /> <
            Route path = "/leaveapplications"
            exact component = { AllLeaveApplications }
            /> <
            Route path = "/UserRoles"
            exact component = { UserRoles }
            /> <
            Route path = "/roles"
            exact component = { Roles }
            /><
            Route path = "/users"
            exact component = { Users }
            /> <
            Route path = "/logout"
            exact component = { Logout }
            /><
            Route path = "dashboard/"
            exact component = { Dashboard }
            />   <
            Route path = "/LeaveApproval"
            exact component = { LeaveApproval }
            /> <
            Route path = "/CancelledLeaves"
            exact component = { CancelledLeaves }
            /> <
            Route path = "/RejectedLeaves"
            exact component = { RejectedLeaves }
            /> <
            Route path = "/ApprovedLeaves"
            exact component = { ApprovedLeaves }
            /> <
            Route path = "/PendingLeaves"
            exact component = { PendingLeaves }
            /> <
            Route path = "/HCompensationApproval"
            exact component = { HCompensationApproval }
            /> <
            Route path = "/HolidayCompensation"
            exact component = { HolidayCompensation }
            /><
            Route path = "/EmployeeMaster"
            exact component = { EmployeeMaster }
            /><
            Route path = "/Calender"
            exact component = { Calender }
            /> <
            Route path = "/BankBranch"
            exact component = { BankBranch }
            /> <
            Route path = "/LeaveTypes"
            exact component = { LeaveTypes }
            /> <
            Route path = "/UCCategories"
            exact component = { UCCategories }
            /> <
            Route path = "/UserCodes"
            exact component = { UserCodes }
            /> <
            Route path = "/Currency"
            exact component = { Currency }
            /> <
            Route path = "/Approvers"
            exact component = { Approvers }
            /> <
            Route path = "/bank"
            exact component = { Bank }
            /> <
            Route path = "/employees"
            exact component = { Employee }
            /> <
            Route path = "/Country"
            exact component = { Country }
            /> <
            Route path = "/County"
            exact component = { County }
            /> <
            Route path = "/Company"
            exact component = { Company }
            /> <
            Route path = "/Department"
            exact component = { Department }
            /> <
            Route path = "/CostCenter"
            exact component = { CostCenter }
            /> <
            Route path = "/contact"
            exact component = { Contact }
            /> <
            Route path = "/kpis"
            exact component = { Kpi }
            /> < /
            Base >
            <
            /Switch> < /
            BrowserRouter >
        );
    }

}

export default App;