import React, { useEffect, useState } from "react";
import DataTable from "../Components/DeleteTable";
import useInput from "../Hooks/useInput";
import usePostData from "../Hooks/usePostData";
import useDelete from "../Hooks/useDelete";
import { Label } from "../Components/Input";
import useGetData from "../Hooks/useGetData";

const HolidayClaims = () => {
    const [Editing, setEditing] = useState(false);
    const initialState = {
        remarks: 'Remarks',
        holiday: "",
        start: new Date(),
        daysWorked: 0,
        amount: 0.0
    };
    const url = "api/HolidayCompensation/";
    const [state, FetchAll] = useGetData(url);
    const { HandleDelete, HandleEditData } = useDelete(url);
    const {
        values,
        HandleChange,
        reset,
        FillData,
        handleSelectChange,
        selectOptions
    } = useInput(initialState);

    useEffect(() => {
        FetchAll();
    }, []);

    let TableHeader = [
        "Employee",
        "Holiday",
        "Start Date",
        "End Date",
        "Days Worked",
        "Status",
        "Amount",
        "Action"
    ];
    let rowData = [
        "employee.email",
        "holiday",
        "start",
        "end",
        "daysWorked",
        "status",
        "amount"
    ];
    const handleEdit = (k,e) => {       
        if (k.status != "Pending-Approval") {
            alert("You cann't edit this record. ");
            setEditing(false);
            return;
        }
        const data = {
            id: k.id,
            holiday: k.holiday,
            remarks: k.remarks,
            start: k.start,
            daysWorked: k.daysWorked,
            amount: k.amount
        };
        FillData(data);
        setEditing(true);
    };
    const handleDelete = k => {
       
        if (k.status != "Pending-Approval") {
            alert("You can not cancel this record, as has been submited for approval.");           
            return;
        }
        HandleDelete(k.id);
    };
    const HandleSubmit = e => {
        e.preventDefault();
        usePostData(url, values);

        reset();
    };
    const HandleUpdate = e => {
        e.preventDefault();
        HandleEditData(values);
        reset();
    };
    const HandleOnClose = e => {
        e.preventDefault();
        setEditing(false);
        reset();
    };
    return (
        <div>
            {state.IsLoading ? (
                <div id="page-loader" className="fade show"><span className="spinner"></span></div>
            ) : (
                    <DataTable
                        page={"Holiday Compensation"}
                        data={state.data}
                        HandleOnClose={HandleOnClose}
                        handleEdit={e => handleEdit(e)}
                        handleDelete={e => handleDelete(e)}
                        rowData={rowData}
                        TableHeader={TableHeader}
                        
                    >
                        <HolidayClaimsForm
                            values={values}
                            selectOptions={selectOptions}
                            IsEditing={Editing}
                            HandleUpdate={HandleUpdate}
                            HandleOnClose={HandleOnClose}
                            HandleSubmit={HandleSubmit}
                            handleSelectChange={handleSelectChange}
                            HandleChange={HandleChange}
                        />
                    </DataTable>
                )}
        </div>
    );
};

export default HolidayClaims;

const HolidayClaimsForm = props => {

    return (
        <div>
            <div className="modal-body">
                <div  className="row">
                    <div className="col-md-10 md-offset-2">
                        <div className="form-group row m-b-10">
                            <Label label="Holiday" isRequired={true} />
                            <div className="col-md-6">
                                <input
                                    type='text'
                                    name='holiday'
                                    required
                                    onChange={props.HandleChange}
                                    value={props.values.holiday}
                                    className='form-control'

                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-10">
                            <Label label="Start Date" isRequired={true} />
                            <div className="col-md-6">
                                <input
                                    type='date'
                                    name='start'
                                    required
                                    onChange={props.HandleChange}
                                    value={props.values.start}
                                    className='form-control'

                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-10">
                            <Label label="Days Worked" isRequired={true} />
                            <div className="col-md-6">
                                <input
                                    type='number'
                                    name='daysWorked'
                                    required
                                    onChange={props.HandleChange}
                                    value={props.values.daysWorked}
                                    className='form-control'

                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-10">
                            <Label label="Amount" isRequired={true} />
                            <div className="col-md-6">
                                <input
                                    type='number'
                                    name='amount'
                                    required
                                    onChange={props.HandleChange}
                                    value={props.values.amount}
                                    className='form-control'

                                />
                            </div>
                        </div>

                        <div className="form-group row m-b-10">
                            <Label label="Remarks" isRequired={true} />
                            <div className="col-md-6">
                                <textarea name='remarks'
                                    required
                                    onChange={props.HandleChange}
                                    value={props.values.remarks} className="form-control" rows="3"></textarea>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a
                    className="btn btn-white"
                    data-dismiss="modal"
                    onClick={props.HandleOnClose}
                >
                    Close
        </a>
                {props.IsEditing ? (
                    <button
                        type="submit"
                        onClick={props.HandleUpdate}
                        className="btn btn-primary"
                    >
                        Update
          </button>
                ) : (
                        <button
                            type="submit"
                            onClick={props.HandleSubmit}
                            className="btn btn-primary"
                        >
                            Apply
          </button>
                    )}
            </div>
        </div>
    );
};
