import React, { useEffect, useState } from "react";
import DataTable from "../Components/DataTable";
import useInput from "../Hooks/useInput";
import usePostData from "../Hooks/usePostData";
import useDelete from "../Hooks/useDelete";
import Input, { Label } from "../Components/Input";
import useGetData from "../Hooks/useGetData";

const Users = () => {
    const [Editing, setEditing] = useState(false);
    const initialState = {
        userName: "",
        email: "",
        phone: ""
    };
    const url = "api/account/";
    const uri = "api/register/";
    const [state, FetchAll] = useGetData(url);
    const { HandleDelete, HandleEditData } = useDelete(url);
    const {
        values,
        HandleChange,
        reset,
        FillData,
        handleSelectChange,
        selectOptions
    } = useInput(initialState);

    useEffect(() => {
        FetchAll();
    }, []);

    let TableHeader = [
        "UserName",
        "Email",
        "Phone Number",
        "Action"
    ];
    let rowData = [
        "userName",
        "email",
        "phoneNumber",

    ];

    const handleEdit = k => {
        const data = {
            id: k.id,
            userName: k.userName,
            email: k.email,
            phone: k.phone
        };
        FillData(data);
        setEditing(true);
    };
    const handleDelete = k => {
        HandleDelete(k);
    };
    const HandleSubmit = e => {
        e.preventDefault();
        usePostData(uri, values);

        reset();
    };
    const HandleUpdate = e => {
        e.preventDefault();
        HandleEditData(values);
        reset();
    };
    const HandleOnClose = e => {
        e.preventDefault();
        setEditing(false);
        reset();
    };
    return (
        <div>
            {state.IsLoading ? (
                <div id="page-loader" className="fade show"><span className="spinner"></span></div>
            ) : (
                    <DataTable
                        page={"Users"}
                        data={state.data}
                        HandleOnClose={HandleOnClose}
                        handleEdit={e => handleEdit(e)}
                        handleDelete={e => handleDelete(e)}
                        rowData={rowData}
                        TableHeader={TableHeader}
                    >
                        <UsersForm
                            values={values}
                            selectOptions={selectOptions}
                            IsEditing={Editing}
                            HandleUpdate={HandleUpdate}
                            HandleOnClose={HandleOnClose}
                            HandleSubmit={HandleSubmit}
                            handleSelectChange={handleSelectChange}
                            HandleChange={HandleChange}
                        />
                    </DataTable>
                )}
        </div>
    );
};

export default Users;

const UsersForm = props => {

    return (
        <div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-10 md-offset-2">
                        <div className="form-group row m-b-10">
                            <Label label="Username" isRequired={true} />
                            <div className="col-md-6">
                                <Input
                                    name="userName"
                                    value={props.values.userName}
                                    HandleChange={props.HandleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-10">
                            <Label label="Email" isRequired={true} />
                            <div className="col-md-6">
                                <Input
                                    name="email"
                                    value={props.values.email}
                                    HandleChange={props.HandleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row m-b-10">
                            <Label label="Phone" isRequired={true} />
                            <div className="col-md-6">
                                <Input
                                    name="phone"
                                    value={props.values.phone}
                                    HandleChange={props.HandleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a
                    className="btn btn-white"
                    data-dismiss="modal"
                    onClick={props.HandleOnClose}
                >
                    Close
        </a>
                {props.IsEditing ? (
                    <button
                        type="submit"
                        onClick={props.HandleUpdate}
                        className="btn btn-primary"
                    >
                        Update
          </button>
                ) : (
                        <button
                            type="submit"
                            onClick={props.HandleSubmit}
                            className="btn btn-primary"
                        >
                            Save
          </button>
                    )}
            </div>
        </div>
    );
};
