import React, { useEffect, useState } from "react";
import DataTable from "../Components/ApproversTable";
import useInput from "../Hooks/useInput";
import useGetData from "../Hooks/useGetData";
import useLeaveApproval from "../Hooks/useLeaveApproval";
import swal from '@sweetalert/with-react';
const url = "api/LeaveApprovalWorkFlow/";

const LeaveApproval = () => {

  const [Editing, setEditing] = useState(false);
  const [state, FetchAll] = useGetData(url);


  useEffect(() => {
    FetchAll();
  }, []);

  let TableHeader = [
    "Employee",
    "Leave Type",
    "Start Date",
    "End Date",
    "Leave days",
    "Action"
  ];
  let rowData = [
    "application.employee.email",
    "application.leaveType.description",
    "application.start",
    "application.end",
    "approvedLeaveDays"
  ];

  const HandleLeaveApproval = k => {
    swal({
      text: "Select leave days to approve.",
      buttons: {
        cancel: "Close",
      },
      content: (
        <ApprovalForm Id={k.id} Days={k.approvedLeaveDays} />)
    }
    )

  };
  const HandleLeaveDecline = k => {
    swal({
      text: "Reject this Leave application!",
      buttons: {
        cancel: "Close",
      },
      content: (
        <DeclineLeave Id={k.id} />)
    }
    )
  }


  return (
    <div>
      {state.IsLoading ? (
        <div id="page-loader" className="fade show"><span className="spinner"></span></div>
      ) : (
          <DataTable
            page={"Leave Approval"}
            data={state.data}
            HandleApproval={e => HandleLeaveApproval(e)}
            HandleDecline={e => HandleLeaveDecline(e)}
            rowData={rowData}
            TableHeader={TableHeader}
          >

          </DataTable>
        )}
    </div>
  );
};

export default LeaveApproval;

const ApprovalForm = (props) => {
  const initialState = {
    approvedLeaveDays: props.Days,
    remarks: ""

  };

  const {
    values,
    HandleChange,
    reset,
  } = useInput(initialState);

  const HandleSubmit = () => {
    useLeaveApproval(url + props.Id, values);
  }

  return (<div>
    <form onSubmit={HandleSubmit} className="margin-bottom-0">
      <div className="form-group m-b-15">
        <input type="number"
          name="approvedLeaveDays"
          value={values.approvedLeaveDays}
          onChange={HandleChange}
          className="form-control form-control-lg" placeholder="Leave Days" required />
      </div>

      <div className="form-group m-b-15">
        <textarea name='remarks'         
          onChange={HandleChange}
          value={values.remarks} placeholder="Remarks" className="form-control" rows="3"></textarea>
      </div>
      <button type="submit" className="btn btn-success btn-block btn-lg">Approve</button>
    </form>
  </div >);
}


const DeclineLeave = (props) => {
  const initialState = {
    remarks: ""

  };

  const {
    values,
    HandleChange,
    reset,
  } = useInput(initialState);

  const HandleSubmit = () => {
    useLeaveApproval(url + 'decline/' + props.Id, values);

  }

  return (<div>
    <form onSubmit={HandleSubmit} className="margin-bottom-0">

      <div className="form-group m-b-15">
        <textarea name='remarks'
          required
          onChange={HandleChange}
          value={values.remarks} placeholder="Remarks" className="form-control" rows="3"></textarea>
      </div>
      <button type="submit" className="btn btn-success btn-block btn-lg">Reject</button>
    </form>
  </div >);
}



